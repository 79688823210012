import React from 'react'
import { Link, useNavigate } from "react-router-dom";

import logo from '../logo.svg';
import LinkTestPage from './LinkTestPage';




export default function Home() {
  const navigate = useNavigate();


  {/* <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group> */}

  // const validateForm = () => {
  //   const newErrors = {};
  //   if (!email) newErrors.email = 'Email is required';
  //   else if (!/\S+@\S+\.\S+/.test(email)) newErrors.email = 'Email is invalid';
  //   if (!password) newErrors.password = 'Password is required';
  //   else if (password.length < 6) newErrors.password = 'Password must be at least 6 characters';
  //   return newErrors;
  // };



  //   // Using window.location
  // window.location = "https://another-website.com"

  // // Using window.open to link between domain
  // window.open("/another-page")

  // // Using window.open to link to another page
  // window.open("https://another-website.com", "_blank")


  return (
    <div className='container'>


      {/* <header className="App-header"> */}
      <header className="pt-5 text-center">
        {/* <div className=' d-flex flex-row justify-content-between' style={{ textAlign: 'left' }}>
          <a href={`/ProductInfo`}> <button className="btn btn-primary">   Take me to ProductInfo  </button>   </a>
          <button className="btn btn-info" onClick={() => window.open("/LinkTestPage")}>   Reload me to LinkTestPage  </button>
          <a href={`/LinkTestPage`}> <button className="btn btn-info">   Take me to LinkTestPage  </button>   </a>
          <a href={`/LinkTestPage`}> link to LinkTestPage   </a>

        </div>

        <div>
          <Link to="/LinkTestPage">Another Page</Link>
          <button onClick={() => navigate("/LinkTestPage")}>Another Page</button>
        </div> */}

        <img src={logo} className="App-logo" alt="logo" />

        <h1 style={{ borderBottom: '3px solid red' }}>Welcome! in the Wonderful World of Music</h1>

      </header>

    </div>
  );
}

