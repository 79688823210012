import React from 'react'
import { Routes, Route } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";


// import logo from './logo.svg';
import ProductInfo from './Pages/ProductInfo';
import Home from './Pages/Home';
import About from './Pages/About';
import LinkTestPage from './Pages/LinkTestPage';
// import OrdersReport from './Pages/ReportTest';


function App() {
  const navigate = useNavigate();

  return (
    <>
      <div className='d-flex px-5 align-items-center' style={{ height: '40px', backgroundColor: 'grey' }}>
        <Link to="/" className='text-white mx-2'>Welcome </Link>

        <div className='ms-auto'>

          <Link to="/LinkTestPage" className='text-white mx-2'>Test Page</Link>
          <Link to="/about" className='text-white mx-2'>About</Link>
          <Link to="/ProductInfo" className='text-white mx-2'>Profile</Link>
          {/* <button onClick={() => navigate("/LinkTestPage")}>Navigate Link Page</button> */}

        </div>
      </div>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ProductInfo" element={<ProductInfo />} />
        <Route path="/about" element={<About />} />
        <Route path="/LinkTestPage" element={<LinkTestPage />} />
      </Routes>



      {/* <h1>Hello</h1> */}
      {/* <Home/> */}
      {/* <ProductInfo /> */}


      {/* <OrdersReport/> */}
    </>
  );
}

export default App;
